import axios from "axios";
import { IPet } from "../models/pet";

const API_URI = process.env.REACT_APP_API_URI;

export async function getPets(limit: number, offset: number): Promise<IPet[]> {
  const { data, status } = await axios.get<IPet[]>(
    `${API_URI}/pets?limit=${limit}&offset=${offset}`
  );
  return data;
}

export async function getPetsByTag(
  tag: string,
  limit: number,
  offset: number
): Promise<IPet[]> {
  const { data, status } = await axios.get<IPet[]>(
    `${API_URI}/petsByTag?tag=${tag}&limit=${limit}&offset=${offset}`
  );
  return data;
}

export async function getPetsByTerm(
  term: string,
  limit: number,
  offset: number
): Promise<IPet[]> {
  const { data, status } = await axios.get<IPet[]>(
    `${API_URI}/petsByTerm?term=${term}&limit=${limit}&offset=${offset}`
  );
  return data;
}

export async function getPetById(id: number): Promise<IPet> {
  const { data, status } = await axios.get<IPet>(`${API_URI}/pet/?id=${id}`);
  return data;
}
