import "pure-react-carousel/dist/react-carousel.es.css";

import { observer } from "mobx-react-lite";
import { CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { toast } from "react-toastify";
import {
  Button,
  Container,
  Divider,
  Flag,
  FlagNameValues,
  Image,
  Label,
  List,
  Menu,
  Segment,
} from "semantic-ui-react";
import { IPet } from "../models/pet";

interface IPetProps {
  pet: IPet;
  mode: string;
}

export const PetView: React.FC<IPetProps> = ({ pet, mode }) => {
  return (
    <Segment raised key={pet.key} className="pet">
      <Menu text className="pet">
        <Menu.Item>
          <Flag name={pet.owner.countryCode as FlagNameValues} />
          <a href={pet.disabled ? pet.owner.uri : pet.uri} target="_blank">
            {pet.name}
          </a>
        </Menu.Item>
      </Menu>
      {pet.images.length > 1 && (
        <CarouselProvider
          naturalSlideWidth={1}
          naturalSlideHeight={1}
          totalSlides={pet.images.length}
        >
          <Slider>
            {pet.images.map((image, index) => (
              <Slide key={index} index={index}>
                <Image fluid src={image} />
              </Slide>
            ))}
          </Slider>
          <div className="dot-pet-external-container">
            <div className="dot-pet-internal-container">
              {pet.images.map((image, index) => (
                <Dot key={index} slide={index} className="dot-pet" />
              ))}
            </div>
          </div>
        </CarouselProvider>
      )}
      {pet.images.length === 1 && (
        <Image fluid src={pet.images[0]} className="pet" />
      )}
      {pet.summary !== "" && (
        <Container className="pet-about">
          <ShowMoreText lines={3} more="more" less="" keepNewLines={true}>
            {pet.summary}
          </ShowMoreText>
        </Container>
      )}
      {pet.tags.length > 0 && (
        <Container className="pet-tags">
          <List horizontal>
            {pet.tags.map((tag, index) => (
              <List.Item key={index} className="pet-tags">
                <Link to={`/tag/${tag}`}>#{tag}</Link>
              </List.Item>
            ))}
          </List>
        </Container>
      )}
      {pet.disabled && (
        <Container className="pet-tags">
          <Label tag>Unavailable</Label>
        </Container>
      )}
      <Divider />
      <Container textAlign="center" className="pet-buttons">
        {mode === "single" && pet.previousId && (
          <Button
            circular
            icon="arrow left"
            as={Link}
            to={`/pet/${pet.previousId}`}
          />
        )}
        <Button
          circular
          color="facebook"
          icon="facebook"
          as="a"
          href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/pet/${pet.id}`}
          target="blank_"
        />
        <Button
          circular
          basic
          icon="clone outline"
          as={Link}
          to={`/pet/${pet.id}`}
          target="blank_"
          rel="noopener noreferrer"
        />
        <CopyToClipboard
          text={`${window.location.origin}/pet/${pet.id}`}
          onCopy={() =>
            toast("Link copied", {
              toastId: pet.id,
            })
          }
        >
          <Button circular basic icon="linkify" as={Link} />
        </CopyToClipboard>
        {mode === "single" && pet.nextId && (
          <Button
            circular
            icon="arrow right"
            as={Link}
            to={`/pet/${pet.nextId}`}
          />
        )}
      </Container>
    </Segment>
  );
};

export default observer(PetView);
