import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Grid, Header, List, Segment } from "semantic-ui-react";

export const About = () => {
  const getTitle = () => {
    return "Choonya - About";
  };

  const getDescription = () => {
    return "Choonya helps homeless pets living in shelters find homes around the world";
  };

  const getImage = () => {
    return `${window.location.origin}/images/choonya.png`;
  };

  const getUrl = () => {
    return window.location.href;
  };

  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />
        <meta property="og:image" content={getImage()} />
        <meta property="og:url" content={getUrl()} />
        <title>{getTitle()}</title>
      </Helmet>
      <Grid centered columns={1}>
        <Grid.Column className="pet">
          <Segment raised>
            <Header as="h2" icon textAlign="center">
              About
            </Header>
            <List size="large" className="about">
              <List.Item className="about">
                <List.Header className="about">Who are we?</List.Header>
                Pet enthusiasts and animal lovers
              </List.Item>
              <List.Item className="about">
                <List.Header className="about">What do we want?</List.Header>
                Every pet has its home and lives happily
              </List.Item>
              <List.Item className="about">
                <List.Header className="about">How do we get it?</List.Header>
                Find homeless pets around the world and share them to caring
                people
              </List.Item>
              <List.Item className="about">
                <List.Header className="about">Where to find us?</List.Header>
                <List.Icon name="telegram plane" color="blue" />
                <List.Content>
                  <a href="https://t.me/choonya_com" target="_blank">
                    t.me/choonya_com
                  </a>
                </List.Content>
              </List.Item>
            </List>
          </Segment>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};
