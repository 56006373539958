import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container } from "semantic-ui-react";
import { About } from "./About";
import { NavBar } from "./NavBar";
import { NotFound } from "./NotFound";
import Ribbon from "./Ribbon";
import { Support } from "./Support";

export const App = () => {
  return (
    <Fragment>
      <Container>
        <ToastContainer
          position="bottom-right"
          autoClose={1000}
          pauseOnHover
          limit={3}
          closeButton={false}
          draggable={false}
        />
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<Ribbon />} />
            <Route path="/pet/:id" element={<Ribbon />} />
            <Route path="/tag/:tag" element={<Ribbon />} />
            <Route path="/find/:term" element={<Ribbon />} />
            <Route path="/about" element={<About />} />
            <Route path="/support" element={<Support />} />
            <Route path="/notfound" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Container>
    </Fragment>
  );
};

export default observer(App);
