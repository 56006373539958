import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Image,
  Input,
  Menu,
  Message,
  Modal,
} from "semantic-ui-react";

export const NavBar = () => {
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchTermError, setSearchTermError] = React.useState(false);
  const navigate = useNavigate();
  const inputRef = useRef<Input>(null);

  const handleClick = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchTerm("");
    setSearchTermError(false);
  };

  const handleFind = () => {
    if (!searchTerm) {
      setSearchTermError(true);
    } else {
      handleClose();
      navigate(`/find/${searchTerm}`);
    }
  };

  const handleOpen = (open: boolean) => {
    setOpen(open);
  };

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  return (
    <>
      <Menu fixed="top" text className="main-menu">
        <Container className="main-menu">
          <Menu.Item header as={Link} to="/">
            <Image src="/images/choonya.png" className="logo" />
          </Menu.Item>
          <Menu.Item position="right" as={Link} to="/about">
            About
          </Menu.Item>
          <Menu.Item as={Link} to="/support">
            Support
          </Menu.Item>
          <Menu.Item as={Link}>
            <Button
              basic
              circular
              icon="search"
              onClick={() => handleOpen(true)}
            />
          </Menu.Item>
        </Container>
      </Menu>

      <Modal
        closeIcon
        dimmer="blurring"
        centered={false}
        size="tiny"
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>Search</Modal.Header>
        <Modal.Content>
          <Message positive>
            <Message.Header>Tips</Message.Header>
            <Message.List>
              <Message.Item>
                Leading exclamation mark (!) indicates that a word must be
                present.
              </Message.Item>
              <Message.Item>
                Leading minus (-) indicates that a word must NOT be present.
              </Message.Item>
              <Message.Item>
                Trailing asterisk (*) matches any characters.
              </Message.Item>
            </Message.List>
            <p>
              E.g. <i>!cat -loud* black</i> means find quiet cats that may be
              black.
            </p>
          </Message>
          <Form>
            <Form.Field>
              <Input
                action={{ icon: "search", onClick: handleFind }}
                ref={inputRef}
                onChange={handleClick}
              />
            </Form.Field>
          </Form>
          <Message negative hidden={!searchTermError}>
            Search input field can't be empty.
          </Message>
        </Modal.Content>
      </Modal>
    </>
  );
};
