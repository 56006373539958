import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button, Container, Grid, Header } from "semantic-ui-react";

export const NotFound = () => {
  const getTitle = () => {
    return "Choonya - Not Found";
  };

  const getDescription = () => {
    return "Choonya helps homeless pets living in shelters find homes around the world";
  };

  const getImage = () => {
    return `${window.location.origin}/images/choonya.png`;
  };

  const getUrl = () => {
    return window.location.href;
  };

  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />
        <meta property="og:image" content={getImage()} />
        <meta property="og:url" content={getUrl()} />
        <title>{getTitle()}</title>
      </Helmet>
      <Grid centered columns={1}>
        <Grid.Column className="pet">
          <Container text>
            <Header as="h2">Nothing found</Header>
            <p>
              We are sorry, but the pet you are looking for has not been found.
            </p>
            <Button circular as={Link} to="/">
              Home
            </Button>
          </Container>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};
