import { makeAutoObservable, runInAction } from "mobx";
import { createContext } from "react";
import { IPet } from "../models/pet";
import {
  getPetById,
  getPets,
  getPetsByTag,
  getPetsByTerm
} from "../utils/api-client";

const LIMIT = 10;

export class PetStore {
  isLoading: boolean;
  petsRegistry: Map<number, IPet>;
  latestId: number;
  latestTag: string;
  latestTerm: string;
  page: number;
  hasMore: boolean;

  get pets(): IPet[] {
    return Array.from(this.petsRegistry.values());
  }

  constructor(
    isLoading: boolean,
    petsRegistry: Map<number, IPet>,
    latestId: number,
    latestTag: string,
    latestTerm: string,
    page: number,
    hasMore: boolean
  ) {
    this.isLoading = isLoading;
    this.petsRegistry = petsRegistry;
    this.latestId = latestId;
    this.latestTag = latestTag;
    this.latestTerm = latestTerm;
    this.page = page;
    this.hasMore = hasMore;

    makeAutoObservable(this);
  }

  reset = () => {
    this.petsRegistry.clear();
    this.latestId = 0;
    this.latestTag = "";
    this.latestTerm = "";
    this.page = 0;
    this.hasMore = false;
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  loadPets = async () => {
    const pets = await getPets(LIMIT, this.page * LIMIT);
    runInAction(() => {
      this.page++;
      this.latestId =
        pets.length > 0 ? Math.min(...pets.map((x) => x.id)) : this.latestId;
      this.hasMore = pets.length === LIMIT;
      pets.forEach((pet) => {
        this.petsRegistry.set(pet.id, pet);
      });
    });
  };

  loadPetsFilteredById = async (id: number) => {
    const pets: IPet[] = [];
    pets.push(await getPetById(id));
    runInAction(() => {
      this.page++;
      this.hasMore = false;
      pets.forEach((pet) => {
        this.petsRegistry.set(pet.id, pet);
      });
    });
  };

  loadPetsFilteredByTag = async (tag: string) => {
    const pets = await getPetsByTag(tag, LIMIT, this.page * LIMIT);
    runInAction(() => {
      if (tag !== this.latestTag) {
        this.reset();
        this.latestTag = tag;
      }
      this.page++;
      this.latestId =
        pets.length > 0 ? Math.min(...pets.map((x) => x.id)) : this.latestId;
      this.hasMore = pets.length === LIMIT;
      pets.forEach((pet) => {
        this.petsRegistry.set(pet.id, pet);
      });
    });
  };

  loadPetsFilteredByTerm = async (term: string) => {
    const pets = await getPetsByTerm(term, LIMIT, this.page * LIMIT);
    runInAction(() => {
      if (term !== this.latestTerm) {
        this.reset();
        this.latestTerm = term;
      }
      this.page++;
      this.latestId =
        pets.length > 0 ? Math.min(...pets.map((x) => x.id)) : this.latestId;
      this.hasMore = pets.length === LIMIT;
      pets.forEach((pet) => {
        this.petsRegistry.set(pet.id, pet);
      });
    });
  };
}

export const petStoreContext = createContext(
  new PetStore(false, new Map<number, IPet>(), 0, "", "", 0, false)
);
