import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Loader } from "semantic-ui-react";
import { IPet } from "../models/pet";
import { petStoreContext } from "../stores/pet-store";
import { NotFound } from "./NotFound";
import PetView from "./PetView";

export const Ribbon = () => {
  const { id, tag, term } = useParams();
  const navigate = useNavigate();
  const [isErrorOccurred, setErrorOccurred] = useState(false);
  const petStore = useContext(petStoreContext);
  const {
    isLoading,
    hasMore,
    pets,
    reset,
    setIsLoading,
    loadPets,
    loadPetsFilteredByTag,
    loadPetsFilteredByTerm,
    loadPetsFilteredById,
  } = petStore;

  const handleGetNextPets = () => {
    setIsLoading(true);
    if (id) {
      loadPetsFilteredById(parseInt(id))
        .catch(() => {
          setErrorOccurred(true);
          navigate("/notfound");
        })
        .finally(() => setIsLoading(false));
    } else if (tag) {
      loadPetsFilteredByTag(tag)
        .catch(() => setErrorOccurred(true))
        .finally(() => setIsLoading(false));
    } else if (term) {
      console.log(term);

      loadPetsFilteredByTerm(term)
        .catch(() => setErrorOccurred(true))
        .finally(() => setIsLoading(false));
    } else {
      loadPets()
        .catch(() => setErrorOccurred(true))
        .finally(() => setIsLoading(false));
    }
  };

  const getTitle = () => {
    if (id && pets && pets[0]) {
      return `Choonya - ${pets[0].name}`;
    } else if (tag) {
      return `Choonya #${tag}`;
    } else if (term) {
      return `Choonya ~ ${term}`;
    } else {
      return "Choonya";
    }
  };

  const getDescription = () => {
    if (id && pets && pets[0]) {
      return pets[0].summary;
    } else if (tag) {
      return `All homeless pets for tag #${tag}`;
    } else if (term) {
      return `All homeless pets for search term #${term}`;
    } else {
      return "Choonya helps homeless pets living in shelters find homes around the world";
    }
  };

  const getImage = () => {
    if (id && pets && pets[0]) {
      return pets[0].images[0];
    } else {
      return `${window.location.origin}/images/choonya.png`;
    }
  };

  const getUrl = () => {
    return window.location.href;
  };

  useEffect(() => {
    reset();
    handleGetNextPets();
  }, [id, tag, term]);

  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />
        <meta property="og:image" content={getImage()} />
        <meta property="og:url" content={getUrl()} />
        <title>{getTitle()}</title>
      </Helmet>

      {!isLoading  && pets.length === 0 && <NotFound />}
      <Grid centered columns={1}>
        <Grid.Column className="pet">
          <InfiniteScroll
            pageStart={0}
            loadMore={handleGetNextPets}
            hasMore={!isLoading && !isErrorOccurred && hasMore}
            initialLoad={false}
            threshold={100}
          >
            <>
              {pets.map((pet: IPet, index: number) => (
                <PetView key={index} pet={pet} mode={id ? "single" : "multiple"} />
              ))}
              <Loader active={isLoading} inline="centered" />
            </>
          </InfiniteScroll>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default observer(Ribbon);
